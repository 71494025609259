<!--
 * @Author: your name
 * @Date: 2022-03-14 10:48:15
 * @LastEditTime: 2022-05-07 18:59:45
 * @LastEditors: baymax-arch 1625750783@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \vue3Boke\src\pages\guestbook\guestbook.vue
-->
<template>
  <div class="guestMain">
    <div>
      <CommentEdit @submitFun="submitFun" :placeholder="placeholder">
        <template #welcome>
          欢迎留言，互换友链请移步
          <router-link to="/links">友邻 </router-link>
          页面申请
        </template>
      </CommentEdit>
    </div>
    <!-- 评论区 -->
    <div class="replyDiv">
      <!-- 评论列表 -->
      <Reply
        class="Reply"
        v-for="comment in comments"
        :key="comment.id"
        :comment="comment"
        @reply="onReply"
        @spread="onSpread"
        :isComment="true"
        :ref="pushRef"
      >
        <!-- 楼中楼 -->
        <template #reply>
          <div
            class="ReplyItem"
            v-if="replys[comment.id] && replys[comment.id].status"
          >
            <Reply
              class="Reply_Item"
              v-for="(reply, index) in replys[comment.id].data"
              @reply="onReply"
              :comment="reply"
              :key="index"
              :isComment="false"
            >
            </Reply>
          </div>
        </template>
      </Reply>
    </div>

    <div class="loadMore">
      <el-button
        v-if="moreFlag"
        size="small"
        type="default"
        @click="getMoreComments()"
        :loading="moreLoading"
      >
        加载更多
      </el-button>
      <div class="withoutEmpty" v-show="!comments.length">
        -- 期待你的留言 --
      </div>

      <div class="without" v-show="withoutFlag && comments.length">
        -- 就这么多咯 --
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import { ref, onActivated, onMounted } from "vue";
import useLoading from "../../hooks/useLoading.js";
import useReply from "../posts/useReply.js";
import CommentEdit from "../../components/commentEdit/commentEdit.vue";
import Reply from "../../components/reply/reply.vue";
import { useHead } from "@vueuse/head";

export default {
  name: "links",
  components: {
    CommentEdit,
    Reply,
  },
  setup() {
    useLoading(); //动画
    let comments = ref({});
    let useReplys = useReply(null, 2);

    onActivated(() => {
      window.scrollTo(0, 0);
      useHead({
        // Can be static or computed
        title: "Baymax-留言板",
        meta: [
          {
            name: `description`,
            content:
              "Baymax是一个前端踩坑记录、日常生活分享、技术分享、博客搬运的基于vue3.x的个人博客",
          },
          {
            name: `keywords`,
            content:
              "Baymax,技术分享,踩坑记录,博客搬运,vue3，一个基于vue3个人博客",
          },
        ],
      });
    });

    return {
      ...useReplys,
      ...mapMutations("headInfo", ["UP_ACTIVE_LINK"]),
    };
  },
  created() {
    this.UP_ACTIVE_LINK("留言");
  },
};
</script>

<style scoped lang="scss">
.loadMore {
  padding-left: 10px;
  margin-top: 5px;
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;

  .el-button {
    padding: 8px !important;
    background-color: var(--white_divBgc) !important;
    color: var(--white_divFc) !important ;
    border: 1px solid var(--white_divBgc) !important;
  }
}
.withoutEmpty {
  padding: 0 0 0px 0;
  width: 100%;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  justify-content: center;
  color: var(--white_divFc_light);
}
.without {
  padding: 0 0 20px 10px;
  color: var(--white_divFc);
  font-size: 14px;
}

.ReplyItem {
  padding: 0px 15px 15px 15px;
}

.Reply {
  margin-top: 25px;
  border-bottom: 1px solid var(--white_divBoc);
  box-sizing: border-box;
}

.Reply:last-child {
  margin-top: 25px;
  border-bottom: none;
}
.Reply_Item:last-child {
  border-bottom: none;
}

.Reply_Item {
  margin-top: 25px;
  border-bottom: 1px solid var(--white_divBoc);
  box-sizing: border-box;
}

.comment {
  padding: 10px;
}

.replyDiv {
  padding: 10px;
}

.guestMain {
  width: 36%;
  min-width: 500px;
  margin: 10px auto;
  margin-bottom: 10px;
  border-radius: 0 0 8px 8px;
  padding-bottom: 30px;
  height: auto;
  background-color: var(--white_divBgc);
  min-width: 600px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  .guestMain {
    width: 100vw;
    overflow: hidden;
    min-width: 0px;
    margin: 0 auto;
    margin-bottom: 10px;
    border-radius: 0 0 8px 8px;
    padding-bottom: 30px;
    height: auto;
    background-color: var(--white_divBgc);
    min-width: 300px;
  }
}
</style>
